<template>
  <LiefengContent>
    <template #title>访问分布</template>
    <template v-slot:toolsbarLeft>
        <DatePicker 
            :options="options"
            @on-change='changeTime'
            type="daterange" 
            show-week-numbers placement="bottom-end" 
            placeholder="选择日期" 
            class="picker"
            v-model="timeData"
            :clearable="false"
            :disabled='disabled'
        ></DatePicker>
    </template>
    <template v-slot:toolsbarRight>
        <Timedropdown :disabled='disabled' @changeTime='getTime' ></Timedropdown>
        <Button @click="$router.push('/appletsurvey')">概况</Button>
        <Button @click="$router.push('/accesstrends')">访问趋势</Button>
        <Button @click="$router.push('/accessspread')" type="primary">访问分布</Button>
        <Button @click="$router.push('/savedata')">数据展示</Button>
        <!-- <Button @click="$router.push('/userportrait')">用户画像</Button> -->
    </template>
    <template #contentArea>
      <div class="card-list">
        <Card class="ocard">
          <p slot="title">访问来源Top10（人数）</p>
          <div class='buttono_list'>
            <div class="left">
              <Tooltip content="最近1天" placement="top">
                <Button :type="indexa == 0 ?'primary':''" @click="changeTypea(0)">日</Button>
              </Tooltip>
              <Tooltip content="最近7天" placement="top">
              <Button :type="indexa == 1 ?'primary':''" @click="changeTypea(1)">周</Button>
              </Tooltip>
              <Tooltip content="最近30天" placement="top">
              <Button :type="indexa == 2 ?'primary':''" @click="changeTypea(2)">月</Button>
              </Tooltip>
            </div>
            
          </div>
          <Bar v-if="userShowType == 1" :data='userData'></Bar>
          <div v-if="userShowType == 2" class="noneTips">
            所选时间范围未包含{{userDifferDay}}天，请重新选择
          </div>
        </Card>
        <Card class="ocard">
          <p slot="title">访问来源Top10（次数）</p>
          <div class='buttono_list'>
            <div class="left">
              <Tooltip content="最近1天" placement="top">
                <Button :type="indexb == 0 ?'primary':''" @click="changeTypeb(0)">日</Button>
              </Tooltip>
              <Tooltip content="最近7天" placement="top">
                <Button :type="indexb == 1 ?'primary':''" @click="changeTypeb(1)">周</Button>
              </Tooltip>
              <Tooltip content="最近30天" placement="top">
                <Button :type="indexb == 2 ?'primary':''" @click="changeTypeb(2)">月</Button>
              </Tooltip>
            </div>
            
          </div>
          <div>

          </div>
          <Bar v-if="numShowType == 1" :data='numData'></Bar>
          <div v-if="numShowType == 2"  class="noneTips">
            所选时间范围未包含{{numDifferDay}}天，请重新选择
          </div>
        </Card>
      </div>

      <Card>
        <p slot="title">访问来源Top5趋势</p>
        <div class='buttono_list'>
            <div class="left">
              <Button :type="indexc == 0 ?'primary':''" @click="changeTypec(0)">人数</Button>
              <Button :type="indexc == 1 ?'primary':''" @click="changeTypec(1)">次数</Button>
            </div>
            <div class="right">
              <!-- <Tooltip content="最近1天" placement="top"> -->
                <Button :type="indexd == 0 ?'primary':''" @click="changeTyped(0)">日</Button>
              <!-- </Tooltip> -->
              <Tooltip content="最近7天" placement="top">
                <Button :type="indexd == 1 ?'primary':''" @click="changeTyped(1)">周</Button>
              </Tooltip>
              <Tooltip content="最近30天" placement="top">
                <Button :type="indexd == 2 ?'primary':''" @click="changeTyped(2)">月</Button>
              </Tooltip>
            </div>
          </div>
          <Vline v-if="lineShowType == 1" :data="lineData"></Vline>
          <div v-if="lineShowType == 2" class="noneTips">
            所选时间范围未包含{{lineDifferDay}}天，请重新选择
          </div>
      </Card>
      
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "./children/LiefengContent";
import Timedropdown from './children/timedropdown';
import Bar from "v-charts/lib/bar.common";
import Vline from "v-charts/lib/line.common";
export default {
  data() {
    return {
      disabled:false,
      indexa:1,
      indexb:1,
      indexc:0,
      indexd:1,

      userShowType:1,
      userDifferDay:0,
      numShowType:1,
      numDifferDay:0,
      lineShowType:1,
      lineDifferDay:0,

      differDay:7,
      options: {
        disabledDate(date) {
          return date && date.valueOf() > new Date() - 1000 * 60 * 60 * 24 * 1;
        }
      },
      cardTime:'',
      timeData:[this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*7), "yyyy-MM-dd"),this.$core.formatDate(new Date(new Date().getTime()-1000*60*60*24*1), "yyyy-MM-dd")],
      numData:{},
      numDay:{
        columns: ["来源", "次数"],
        rows: [
        ],
      },
      numWek:{
        columns: ["来源", "次数"],
        rows: [
        ],
      },
      numMon:{
        columns: ["来源", "次数"],
        rows: [
        ],
      },

      userData:{},
      userDay:{
        columns: ["来源", "人数"],
        rows: [
        ],
      },
      userWek:{
        columns: ["来源", "人数"],
        rows: [
        ],
      },
      userMon:{
        columns: ["来源", "人数"],
        rows: [
        ],
      },

      lineData:{},
      dayUv: {
        columns: ["时间"],
        rows: [],
      },
      wekUv: {
        columns: ["时间"],
        rows: [],
      },
      monUv: {
        columns: ["时间"],
        rows: [],
      },
      dayPv: {
        columns: ["时间"],
        rows: [],
      },
      wekPv: {
        columns: ["时间"],
        rows: [],
      },
      monPv: {
        columns: ["时间"],
        rows: [],
      },
    };
  },
  created(){
    this.numData = this.numDay
    this.userData = this.userDay
    this.lineData = this.wekUv
    this.getData()
    
  },
  methods: {
    
   getTime(val){
      this.timeData = val
      this.differDay = (new Date(this.timeData[1]).getTime() - new Date(this.timeData[0]).getTime()) / 86400000
      if(this.indexb == 0){ 
        this.numShowType = 1
        this.numData = this.numDay
      }else if(this.indexb == 1){
        if(this.differDay<7){
          this.numShowType = 2
          this.numDifferDay = 7
        }
        else{
          this.numShowType = 1
          this.numData = this.numWek
        }
      }else {
        if(this.differDay<30){
          this.numDifferDay = 30
          this.numShowType = 2
        }
        else {
          this.numShowType = 1
          this.numData = this.numMon
        }
      }

      if(this.indexa == 0){
        this.userShowType = 1
        this.userData = this.userDay
      }else if(this.indexa == 1){
        if(this.differDay<7){
          this.userShowType = 2
          this.userDifferDay = 7
        }
        else{
          this.userShowType = 1
          this.userData = this.userWek
        }
      }else {
        if(this.differDay<30){
          this.userDifferDay = 30
          this.userShowType = 2
        }
        else{
          this.userShowType = 1
          this.userData = this.userMon
        }
      }

      if( this.indexc == 0){
        if(this.indexd == 0){
          this.lineData = this.dayUv
        }else if(this.indexd == 1){
          if(this.differDay<7){
            this.lineShowType = 2
            this.lineDifferDay = 7
          }else {
            this.lineShowType = 1
            this.lineData = this.wekUv
          }
        }else {
          if(this.differDay<30){
            this.lineShowType = 2
            this.lineDifferDay = 30
          }else {
            this.lineShowType = 1
            this.lineData = this.monUv
          }
        }
      }else {
        if(this.indexd == 0){
          this.lineData = this.dayPv
        }else if(this.indexd == 1){
          if(this.differDay<7){
            this.lineShowType = 2
            this.lineDifferDay = 7
          }else {
            this.lineShowType = 1
            this.lineData = this.wekPv
          }
        }else {
          if(this.differDay<30){
            this.lineShowType = 2
            this.lineDifferDay = 30
          }else {
            this.lineShowType = 1
            this.lineData = this.monPv
          }
        }
      }
      if(this.indexd == 0){
        if(this.indexc == 0){
          this.lineData = this.dayUv
        }else{
          this.lineData = this.dayPv
        }
      }else if(this.indexd == 1){
        if(this.differDay<7){
          this.lineShowType = 2
          this.lineDifferDay = 7
        }else {
          this.lineShowType = 1
          if(this.indexc == 0){
            this.lineData =  this.wekUv
          }else{
            this.lineData =  this.wekPv
          }
        }
      }else {
        if(this.differDay<30){
          this.lineShowType = 2
          this.lineDifferDay = 30
        }else {
          this.lineShowType = 1
          if(this.indexc == 0){
            this.lineData =  this.monUv
          }else{
            this.lineData =  this.monPv
          }
        }
      }

      this.getData()
    },
    changeTime(val){
      this.timeData[0] = val[0]
      this.timeData[1] = val[1]
      this.differDay = (new Date(this.timeData[1]).getTime() - new Date(this.timeData[0]).getTime()) / 86400000

      if(this.indexb == 0){ 
        this.numShowType = 1
        this.numData = this.numDay
      }else if(this.indexb == 1){
        if(this.differDay<7){
          this.numShowType = 2
          this.numDifferDay = 7
        }
        else{
          this.numShowType = 1
          this.numData = this.numWek
        }
      }else {
        if(this.differDay<30){
          this.numDifferDay = 30
          this.numShowType = 2
        }
        else {
          this.numShowType = 1
          this.numData = this.numMon
        }
      }

      if(this.indexa == 0){
        this.userShowType = 1
        this.userData = this.userDay
      }else if(this.indexa == 1){
        if(this.differDay<7){
          this.userShowType = 2
          this.userDifferDay = 7
        }
        else{
          this.userShowType = 1
          this.userData = this.userWek
        }
      }else {
        if(this.differDay<30){
          this.userDifferDay = 30
          this.userShowType = 2
        }
        else{
          this.userShowType = 1
          this.userData = this.userMon
        }
      }
      if( this.indexc == 0){
        if(this.indexd == 0){
          this.lineShowType = 1
          this.lineData = this.dayUv
        }else if(this.indexd == 1){
          if(this.differDay<7){
            this.lineShowType = 2
            this.lineDifferDay = 7
          }else {
            this.lineShowType = 1
            this.lineData = this.wekUv
          }
        }else {
          if(this.differDay<30){
            this.lineShowType = 2
            this.lineDifferDay = 30
          }else {
            this.lineShowType = 1
            this.lineData = this.monUv
          }
        }
      }else {
        if(this.indexd == 0){
          this.lineShowType = 1
          this.lineData = this.dayPv
        }else if(this.indexd == 1){
          if(this.differDay<7){
            this.lineShowType = 2
            this.lineDifferDay = 7
          }else {
            this.lineShowType = 1
            this.lineData = this.wekPv
          }
        }else {
          if(this.differDay<30){
            this.lineShowType = 2
            this.lineDifferDay = 30
          }else {
            this.lineShowType = 1
            this.lineData = this.monPv
          }
        }
      }
      if(this.indexd == 0){
        this.lineShowType = 1
        if(this.indexc == 0){
          this.lineData = this.dayUv
        }else{
          this.lineData = this.dayPv
        }
      }else if(this.indexd == 1){
        if(this.differDay<7){
          this.lineShowType = 2
          this.lineDifferDay = 7
        }else {
          this.lineShowType = 1
          if(this.indexc == 0){
            this.lineData =  this.wekUv
          }else{
            this.lineData =  this.wekPv
          }
        }
      }else {
        if(this.differDay<30){
          this.lineShowType = 2
          this.lineDifferDay = 30
        }else {
          this.lineShowType = 1
          if(this.indexc == 0){
            this.lineData =  this.monUv
          }else{
            this.lineData =  this.monPv
          }
        }
      }
      
      this.getData()
    },
    changeTypeb(index){
      this.indexb = index
      if(this.indexb == 0){ 
        this.numShowType = 1
        this.numData = this.numDay
      }else if(this.indexb == 1){
        if(this.differDay<7){
          this.numShowType = 2
          this.numDifferDay = 7
        }
        else{
          this.numShowType = 1
          this.numData = this.numWek
        }
      }else {
        if(this.differDay<30){
          this.numDifferDay = 30
          this.numShowType = 2
        }
        else {
          this.numShowType = 1
          this.numData = this.numMon
        }
      }
    },
    changeTypea(index){
      this.indexa = index
      if(this.indexa == 0){
        this.userShowType = 1
        this.userData = this.userDay
      }else if(this.indexa == 1){
        if(this.differDay<7){
          this.userShowType = 2
          this.userDifferDay = 7
        }
        else{
          this.userShowType = 1
          this.userData = this.userWek
        }
      }else {
        if(this.differDay<30){
          this.userDifferDay = 30
          this.userShowType = 2
        }
        else{
          this.userShowType = 1
          this.userData = this.userMon
        }
      }
    },
    changeTypec(index){
      this.indexc = index
      if( this.indexc == 0){
        if(this.indexd == 0){
          this.lineShowType = 1
          this.lineData = this.dayUv
        }else if(this.indexd == 1){
          if(this.differDay<7){
            this.lineShowType = 2
            this.lineDifferDay = 7
          }else {
            this.lineShowType = 1
            this.lineData = this.wekUv
          }
        }else {
          if(this.differDay<30){
            this.lineShowType = 2
            this.lineDifferDay = 30
          }else {
            this.lineShowType = 1
            this.lineData = this.monUv
          }
        }
      }else {
        if(this.indexd == 0){
          this.lineShowType = 1
          this.lineData = this.dayPv
        }else if(this.indexd == 1){
          if(this.differDay<7){
            this.lineShowType = 2
            this.lineDifferDay = 7
          }else {
            this.lineShowType = 1
            this.lineData = this.wekPv
          }
        }else {
          if(this.differDay<30){
            this.lineShowType = 2
            this.lineDifferDay = 30
          }else {
            this.lineShowType = 1
            this.lineData = this.monPv
          }
        }
      }
    },
    changeTyped(index){
      this.indexd = index
      if(this.indexd == 0){
        this.lineShowType = 1
        if(this.indexc == 0){
          this.lineData = this.dayUv
        }else{
          this.lineData = this.dayPv
        }
      }else if(this.indexd == 1){
        if(this.differDay<7){
          this.lineShowType = 2
          this.lineDifferDay = 7
        }else {
          this.lineShowType = 1
          if(this.indexc == 0){
            this.lineData =  this.wekUv
          }else{
            this.lineData =  this.wekPv
          }
        }
      }else {
        if(this.differDay<30){
          this.lineShowType = 2
          this.lineDifferDay = 30
        }else {
          this.lineShowType = 1
          if(this.indexc == 0){
            this.lineData =  this.monUv
          }else{
            this.lineData =  this.monPv
          }
        }
      }
    },
    getData(){
      this.disabled = true
      this.$Message.loading({
        content: "数据正在加载中",
        duration: 0
      });
      this.numDay.rows = []
      this.numWek.rows = []
      this.numMon.rows = []
      this.userDay.rows = []
      this.userWek.rows = []
      this.userMon.rows = []
      this.dayUv.columns = ['时间']
      this.wekUv.columns = ['时间']
      this.monUv.columns = ['时间']
      this.dayPv.columns = ['时间']
      this.wekPv.columns = ['时间']
      this.monPv.columns = ['时间']
      this.dayUv.rows = []
      this.wekUv.rows = []
      this.monUv.rows = []
      this.dayPv.rows = []
      this.wekPv.rows = []
      this.monPv.rows = []
      let timeData = JSON.parse(JSON.stringify(this.timeData)) // 
      this.$get('/statistic/api/symanage/pc/weappStat/getAllData',
        {
          startDate:timeData[0],
          endDate:timeData[1]
        }).then( res => {
          this.disabled = false
          this.$Message.destroy();
          if(res.code == 200 && res.data){
            let dayPv = JSON.parse(JSON.stringify(res.data.distributionDayPv[timeData[1]]))

            dayPv.sort(this.sorts_fun('pv')).map(item => {
              this.numDay.rows.push({
                来源:item.statScene,次数:item.pv
              })
            })
            
            let wekPv = JSON.parse(JSON.stringify(res.data.distributionWeekPv[timeData[1]]))
            wekPv.sort(this.sorts_fun('pv')).map(item => {
              this.numWek.rows.push({
                来源:item.statScene,次数:item.pv
              })
            })

            let monPv = JSON.parse(JSON.stringify(res.data.distributionMonthPv[timeData[1]]))
            monPv.sort(this.sorts_fun('pv')).map(item => {
              this.numMon.rows.push({
                来源:item.statScene,次数:item.pv
              })
            })

            let dayUv = JSON.parse(JSON.stringify(res.data.distributionDayUv[timeData[1]])) 
            dayUv.sort(this.sorts_fun('uv')).map(item => {
              this.userDay.rows.push({
                来源:item.statScene,人数:item.uv
              })
            })
            let wekUv = JSON.parse(JSON.stringify(res.data.distributionWeekUv[timeData[1]])) 
            wekUv.sort(this.sorts_fun('uv')).map(item => {
              this.userWek.rows.push({
                来源:item.statScene,人数:item.uv
              })
            })

            let monUv = JSON.parse(JSON.stringify(res.data.distributionMonthUv[timeData[1]]))
            monUv.sort(this.sorts_fun('uv')).map(item => {
              this.userMon.rows.push({
                来源:item.statScene,人数:item.uv
              })
            })

            // 拿x日期
            let dateList = []
            res.data.weappDailyStatTotalVoList.map( item => {
              dateList.push(item.statDate)
            })
            res.data.distributionWeekUv[timeData[1]].map( (e,i) => {
              if( i<5 ){
                this.dayUv.columns.push(e.statScene)
                this.wekUv.columns.push(e.statScene)
                this.monUv.columns.push(e.statScene)
                this.dayPv.columns.push(e.statScene)
                this.wekPv.columns.push(e.statScene)
                this.monPv.columns.push(e.statScene)
              }
            })


            dateList.map( e => {
              this.dayUv.rows.push(
                {时间:e}
              )
              this.wekUv.rows.push(
                {时间:e}
              )
              this.monUv.rows.push(
                {时间:e}
              )
              this.dayPv.rows.push(
                {时间:e}
              )
              this.wekPv.rows.push(
                {时间:e}
              )
              this.monPv.rows.push(
                {时间:e}
              )
            })

             // 人7
            this.wekUv.columns.map( (item,index) => {
              if(index >0 ){
                this.dayUv.rows.map( e => {
                  e[item] = 0
                })
                this.wekUv.rows.map( e => {
                  e[item] = 0
                })
                this.monUv.rows.map( e => {
                  e[item] = 0
                })
                this.dayPv.rows.map( e => {
                  e[item] = 0
                })
                this.wekPv.rows.map( e => {
                  e[item] = 0
                })
                this.monPv.rows.map( e => {
                  e[item] = 0
                })
              }
            })

            this.dayUv.rows.map(item => {
              for(let oitem in res.data.distributionDayUv){
                if(item['时间'] == oitem){
                  res.data.distributionDayUv[oitem].map( e => {
                    for(let iItem in item){
                      if(e.statScene == iItem){
                        item[iItem] = e.uv
                      }
                    }
                  })
                }
              }
            })
            

            this.wekUv.rows.map(item => {
              for(let oitem in res.data.distributionWeekUv){
                if(item['时间'] == oitem){
                  res.data.distributionWeekUv[oitem].map( e => {
                    for(let iItem in item){
                      if(e.statScene == iItem){
                        item[iItem] = e.uv
                      }
                    }
                  })
                }
              }
            })

            this.monUv.rows.map(item => {
              for(let oitem in res.data.distributionMonthUv){
                if(item['时间'] == oitem){
                  res.data.distributionMonthUv[oitem].map( e => {
                    for(let iItem in item){
                      if(e.statScene == iItem){
                        item[iItem] = e.uv
                      }
                    }
                  })
                }
              }
            })

            this.dayPv.rows.map(item => {
              for(let oitem in res.data.distributionDayPv){
                if(item['时间'] == oitem){
                  res.data.distributionDayPv[oitem].map( e => {
                    for(let iItem in item){
                      if(e.statScene == iItem){
                        item[iItem] = e.pv
                      }
                    }
                  })
                }
              }
            })

            this.wekPv.rows.map(item => {
              for(let oitem in res.data.distributionWeekPv){
                if(item['时间'] == oitem){
                  res.data.distributionWeekPv[oitem].map( e => {
                    for(let iItem in item){
                      if(e.statScene == iItem){
                        item[iItem] = e.pv
                      }
                    }
                  })
                }
              }
            })

            this.monPv.rows.map(item => {
              for(let oitem in res.data.distributionMonthPv){
                if(item['时间'] == oitem){
                  res.data.distributionMonthPv[oitem].map( e => {
                    for(let iItem in item){
                      if(e.statScene == iItem){
                        item[iItem] = e.pv
                      }
                    }
                  })
                }
              }
            })
          }else{
            this.$Message.error({
              content:'获取数据失败',
              background:true
            })
          }
        })
    },
    sorts_fun(e) {
      return function(a, b) {
          var v1 = a[e];
          var v2 = b[e];
          return v1 - v2;
      };
    }
  },
  components: {
    LiefengContent,
    Timedropdown,
    Bar,
    Vline
  },
};
</script>
    
<style scoped lang='less'>
.picker{
    position: absolute;
    right: 0;
}
.card-list{
  margin: 0 0 20px 0;
  display: flex;
  justify-content: space-between;
  .ocard{
    width:48%;
  }
}
.noneTips{
  width: 100%;
  height: 400px;
  text-align: center;
  line-height: 400px;
  font-size: 16px;
  color: #999999;
  
}
.buttono_list{
    display: flex;
    button {
      margin-right: 10px;
    }
    .left {
      width: 50%;
    }
    .right {
      text-align: right;
      width: 50%;
    }
  }
/deep/.ivu-card-head {
  border: none;
}
</style>